import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { Theme as MaterialTheme } from '@mui/material/styles';
import { BoxTypeMap } from '@mui/system';
import { OverridableComponent } from '@mui/types';

export const FontIcon: OverridableComponent<BoxTypeMap<{icon: FontAwesomeIconProps}, 'div', MaterialTheme>> = (props: any) => {
  const {icon, ...box} = props;
  return <Box {...box}>
    <FontAwesomeIcon {...icon} />
  </Box>
}
