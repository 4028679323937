import { Box, Typography } from '@mui/material';
import React from 'react';
import { faSterlingSign } from '@fortawesome/sharp-thin-svg-icons';
import { FontIcon } from './font-icon';

export type CurrencyAmountProps = {
  amount: string;
  currency?: '£';
}

export function CurrencyAmount({ amount, currency }: CurrencyAmountProps) {
  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <FontIcon icon={{icon: faSterlingSign, fontSize: '20px'}} />
      <Box>
        <Typography fontSize="40px" fontWeight="100">{amount}</Typography>
      </Box>
    </Box>
  );
}
