import { Box, Typography } from '@mui/material';
import React from 'react';

export type MetricType = 'M3' | 'Ah';
export type MetricProps = {
  amount: string;
  type: MetricType;
}

function UsageType({ type }: { type: MetricType }) {
  if(type === 'M3') {
    return (
      <Box display="flex" alignItems="flex-start">
        <Typography variant="h5">M</Typography>
        <Typography fontSize={8} fontWeight="100">3</Typography>
      </Box>
    );
  }
  return <Typography variant="h5">{type}</Typography>
}

export function Metric({ amount, type }: MetricProps) {
  return (
    <Box display="flex" gap={0.5} alignItems="baseline">
      <Typography variant="h4">{amount}</Typography>
      <UsageType type={type}/>
    </Box>
  );
}
