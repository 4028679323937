import { Box, Typography } from '@mui/material';
import React from 'react';
import { SummaryCard, SummaryCardProps } from '../../components/summary-card';

type SummaryProps = {
  summaries: SummaryCardProps[]
}

export function Summary({summaries}: SummaryProps) {
  return (
    <Box display="flex" flexDirection="column" gap={1.5} mt={5}>
      <Typography variant="h5" fontWeight={600} color="#383838">Summary</Typography>
      <Box display="flex" flexDirection="column" gap={2} >
        {summaries.map(summary => <SummaryCard {...summary} />)}
      </Box>
    </Box>
  )
}
