import { Box, Container } from '@mui/material';
import React from 'react';
import { Summary } from './features/summary';
import Gravatar from 'react-gravatar';

function App() {
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      background: 'linear-gradient(0deg, #FFFFFF, #B9AEA7)',
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Box display="flex" p={1.25}>
          <Gravatar
            default="mm"
            email={"chris.barbour@hexlabs.io"}
            height={30}
            width={30}
            style={{ borderRadius: '50vh', padding: '1px', backgroundColor: 'rgba(0,0,0,0.06)'}}
          />
          <Box flex={1}/>

        </Box>
        <Summary summaries={[
          {metric: {amount: '56000', type: 'M3'}, balanceAmount: { amount: '1079.02', currency: '£'}, utilityType: 'gas'},
          {metric: {amount: '23423', type: 'Ah'}, balanceAmount: { amount: '593.99', currency: '£'}, utilityType: 'electricity'},
        ]} />
      </Container>
    </Box>
  );
}

export default App;
